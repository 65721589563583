import HoroscopeService from "@/services/horoscope.service";
import { useMutation } from "@tanstack/vue-query";

export function useGenerateHoroscope() {
  return useMutation<any, Error, string>({
    mutationFn: async (data: any) => {
      //Simulation
      // return new Promise((resolve) => {
      //   setTimeout(() => {
      //     resolve(
      //       "Sam, today your natural energy is at its peak—embrace it! Whether you're tackling a new challenge or pursuing a passion, the stars are aligned in your favor. Trust your instincts and take that bold step forward; success is within your reach. Remember, your confidence is your greatest strength—use it to inspire those around you!"
      //     );
      //   }, 1500);
      // });
      return await HoroscopeService.submitHoroscopeCreation(data);
    },
    onSuccess: (data: string) => {
      return data;
    },
    onError: (error: Error) => {
      console.error(error);
    },
  });
}
