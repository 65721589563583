import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, Transition as _Transition, withCtx as _withCtx, Fragment as _Fragment } from "vue"
import _imports_0 from '@/assets/logo-1.webp'
import _imports_1 from '@/assets/img/hero-showcase.webp'


const _hoisted_1 = { class: "main px-10 py-5" }
const _hoisted_2 = { class: "body flex mt-10 px-10 justify-between items-center *:z-10" }
const _hoisted_3 = {
  key: 0,
  class: "content flex flex-col w-[49%]"
}
const _hoisted_4 = { class: "sign-up-card bg-white rounded-xl py-5 px-16 flex flex-col items-center mt-10 w-[80%] font-crimson" }
const _hoisted_5 = {
  key: 0,
  class: "mb-4 text-red-800"
}
const _hoisted_6 = {
  key: 0,
  src: _imports_1,
  alt: "Daily Horoscope & Astrology Mobile App",
  class: "w-[30%]"
}

import FeatureBulletPoint from "@/components/waiting_list/FeatureBulletPoint.vue";
import OnboardingModal from "@/components/waiting_list/OnboardingModal.vue";
import WhatIsItSection from "@/components/WhatIsItSection.vue";
import { useWaitinglist } from "@/composables/useWaitinglist";
import Toast from "primevue/toast";
import { useToast } from "primevue/usetoast";
import { onMounted, ref } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'SignUpView',
  setup(__props) {

const displayContent = ref(false);

const fullName = ref("");
const email = ref("");

const isOnboarding = ref(false);

// localStorage.clear();

// Destructure the composable for Firebase operations
const { saveUser, errorMessage, hasSignedUpBefore } = useWaitinglist();

const toast = useToast();

onMounted(() => {
  displayContent.value = true;

  const urlParams = new URLSearchParams(window.location.search);
  const referralId = urlParams.get("referral");
  if (referralId) {
    localStorage.setItem("referralId", referralId);
  }
});

const onJoinWaitlistClick = async () => {
  if (!fullName.value || !email.value) {
    errorMessage.value = "Please enter your details";
    return;
  }
  if (hasSignedUpBefore()) {
    errorMessage.value = "Seems like you already signed up";
    return;
  }
  const referralId = localStorage.getItem("referralId");

  await saveUser(fullName.value, email.value, referralId);

  if (!errorMessage.value) {
    toast.add({
      severity: "success",
      summary: "Joined Successfully",
      detail: "You have been added to waitlist",
      life: 1500,
    });
    isOnboarding.value = true;
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[10] || (_cache[10] = _createElementVNode("header", { class: "w-full flex items-center justify-between bg-[#151515] px-10 py-3" }, [
      _createElementVNode("a", {
        href: "/",
        class: "no-underline"
      }, [
        _createElementVNode("img", {
          alt: "Affirmity: AI-Powered Astrology & Affirmations Logo",
          src: _imports_0,
          class: "h-8"
        })
      ])
    ], -1)),
    _createElementVNode("div", _hoisted_1, [
      _cache[8] || (_cache[8] = _createElementVNode("div", { class: "w-56 h-56 rounded-full blur-[150px] bg-[#F2BDBA] absolute bottom-[20%] left-[30%] z-0" }, null, -1)),
      _cache[9] || (_cache[9] = _createElementVNode("div", { class: "w-40 h-40 rounded-full blur-[100px] bg-[#C7C7FF] absolute top-[15%] right-[22%] z-0" }, null, -1)),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_Transition, { name: "slide-right" }, {
          default: _withCtx(() => [
            (displayContent.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _cache[6] || (_cache[6] = _createElementVNode("h1", { class: "font-crimson text-[#151515] text-4xl font-bold" }, " AI-Powered Astrology & Affirmations ", -1)),
                  _cache[7] || (_cache[7] = _createElementVNode("h4", { class: "font-crimson text-[#505050] text-xl mb-6" }, " Join waitlist to get early access and free gifts ", -1)),
                  _createVNode(FeatureBulletPoint, {
                    feature: "Daily Personalised Horoscope",
                    class: "mb-2"
                  }),
                  _createVNode(FeatureBulletPoint, {
                    feature: "Daily Affirmations",
                    class: "mb-2"
                  }),
                  _createVNode(FeatureBulletPoint, { feature: "AI Chat: Ask the Stars Anything" }),
                  _createElementVNode("div", _hoisted_4, [
                    _cache[3] || (_cache[3] = _createElementVNode("h1", { class: "text-black text-3xl font-bold text-center mb-8" }, " Get Free AI-Powered Horoscope for Today ", -1)),
                    _cache[4] || (_cache[4] = _createElementVNode("h3", null, null, -1)),
                    _withDirectives(_createElementVNode("input", {
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((fullName).value = $event)),
                      placeholder: "Full Name",
                      class: "border-[#E2E8F0] bg-[#F1F5F9] border-2 rounded-lg px-3 py-2 w-full mb-4 outline-none"
                    }, null, 512), [
                      [_vModelText, fullName.value]
                    ]),
                    _withDirectives(_createElementVNode("input", {
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((email).value = $event)),
                      placeholder: "Email",
                      class: "border-[#E2E8F0] bg-[#F1F5F9] border-2 rounded-lg px-3 py-2 w-full mb-4 outline-none"
                    }, null, 512), [
                      [_vModelText, email.value]
                    ]),
                    (_unref(errorMessage))
                      ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_unref(errorMessage)), 1))
                      : _createCommentVNode("", true),
                    _createElementVNode("button", {
                      class: "bg-[#151515] rounded-lg px-3 py-2 w-full hover:opacity-85",
                      onClick: onJoinWaitlistClick
                    }, _cache[2] || (_cache[2] = [
                      _createElementVNode("p", { class: "text-[#9CA3AF] text-lg" }, "Join waitlist", -1)
                    ])),
                    _cache[5] || (_cache[5] = _createElementVNode("h3", { class: "text-[#9CA3AF] text-lg mt-5 text-center" }, [
                      _createTextVNode(" Get chance to win "),
                      _createElementVNode("span", { class: "font-black text-[#3e4146]" }, "6 free months"),
                      _createTextVNode(" premium subscription. ")
                    ], -1))
                  ])
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        _createVNode(_Transition, { name: "slide-left" }, {
          default: _withCtx(() => [
            (displayContent.value)
              ? (_openBlock(), _createElementBlock("img", _hoisted_6))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ])
    ]),
    _createVNode(WhatIsItSection),
    _createVNode(OnboardingModal, {
      show: isOnboarding.value,
      fullName: fullName.value
    }, null, 8, ["show", "fullName"]),
    _createVNode(_unref(Toast), { position: "top-center" })
  ], 64))
}
}

})