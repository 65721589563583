import { db } from "@/firebaseConfig";
import { addDoc, collection, doc, getDoc, setDoc } from "firebase/firestore";

class UserService {
  static async saveUserInfo(userId: string, data: object) {
    try {
      await setDoc(
        doc(db, "waitlist", userId),
        {
          id: userId,
          ...data,
        },
        { merge: true }
      );
    } catch (error) {
      console.error("Error:", error);
    }
  }

  static async getUser(userId: string) {
    try {
      const docRef = doc(db, "waitlist", userId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        return docSnap.data();
      }
      return null;
    } catch (error) {
      console.error("Error:", error);
    }
  }
}

export default UserService;
