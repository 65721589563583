import UserService from "@/services/user.service";
import { useMutation } from "@tanstack/vue-query";

export function usePersonalDashboard() {
  return useMutation<any, Error, string>({
    mutationFn: async (userId: string) => {
      return await UserService.getUser(userId);
    },
    onSuccess: (data: string) => {
      return data;
    },
    onError: (error: Error) => {
      console.error(error);
    },
  });
}
