import { ref } from "vue";
import {
  getFirestore,
  collection,
  query,
  where,
  orderBy,
  limit,
  getDocs,
  doc,
  getDoc,
  DocumentData,
  AggregateQuerySnapshot,
  getCountFromServer,
  AggregateField,
} from "firebase/firestore";

const db = getFirestore();

// Define types for the composable
interface NextMilestone {
  neededReferrals: number;
  nextReferralCount: number;
}

export function useReferralLeaderboard() {
  const userRank = ref<number | null>(null);
  const nextMilestone = ref<NextMilestone | null>(null);
  const errorMessage = ref<string | null>(null);

  // Function to get the number of users with more referrals using count()
  const getUsersWithMoreReferralsCount = async (
    referralCount: number
  ): Promise<number> => {
    try {
      const q = query(
        collection(db, "waitlist"),
        where("referralCount", ">", referralCount)
      );

      const snapshot: AggregateQuerySnapshot<{
        count: AggregateField<number>;
      }> = await getCountFromServer(q);

      // Extract the count from the snapshot
      return snapshot.data().count as unknown as number;
    } catch (error) {
      console.error(
        "Error fetching count of users with more referrals:",
        error
      );
      return 12;
    }
  };

  // Function to get the next milestone (users with more referrals)
  const getNextMilestone = async (
    referralCount: number
  ): Promise<NextMilestone | null> => {
    try {
      const q = query(
        collection(db, "waitlist"),
        where("referralCount", ">", referralCount),
        orderBy("referralCount", "asc"),
        limit(1) // Get just the next user
      );

      const snapshot = await getDocs(q);
      if (!snapshot.empty) {
        const nextUser = snapshot.docs[0].data();
        const neededReferrals =
          (nextUser.referralCount as number) - referralCount + 1;

        return {
          neededReferrals,
          nextReferralCount: nextUser.referralCount as number,
        };
      }
      return null;
    } catch (error) {
      console.error("Error fetching next milestone:", error);
      errorMessage.value = "Unable to fetch next milestone";
      return null;
    }
  };

  // Main function to get rank and next milestone
  const getUserRankAndNextMilestone = async (user: any): Promise<void> => {
    try {
      const referralCount = user?.referralCount || 0;

      // Get number of users with more referrals
      const usersWithMoreReferrals = await getUsersWithMoreReferralsCount(
        referralCount
      );

      // Calculate rank
      userRank.value = usersWithMoreReferrals + 1; // The user's rank is the number of users above them + 1

      // Get next milestone
      nextMilestone.value = await getNextMilestone(referralCount);
    } catch (error) {
      console.error("Error fetching rank and milestone:", error);
      errorMessage.value = "Unable to fetch ranking information";
    }
  };

  return {
    userRank,
    nextMilestone,
    getUserRankAndNextMilestone,
    errorMessage,
  };
}
