import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "./assets/index.css";
import "primeicons/primeicons.css";
import { VueQueryPlugin } from "@tanstack/vue-query";
import PrimeVue from "primevue/config";
import Aura from "@primevue/themes/aura";
import VueCountdown from "@chenfengyuan/vue-countdown";
import ToastService from "primevue/toastservice";

createApp(App)
  .use(router)
  .use(VueQueryPlugin)
  .use(PrimeVue, {
    theme: {
      preset: Aura,
      options: {
        darkModeSelector: "false",
      },
    },
  })
  .use(ToastService)
  .mount("#app");
