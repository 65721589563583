import { getFunctions, httpsCallable } from "firebase/functions"; // Import Firebase Cloud Functions

class HoroscopeService {
  static async submitHoroscopeCreation(data: any) {
    const functions = getFunctions(undefined, "europe-west2");
    try {
      const submitHoroscope = httpsCallable(functions, "submitHoroscope");
      const result = await submitHoroscope(data);

      return result.data;
    } catch (error) {
      console.error("Error:", error);
      return "Error submitting data. Please try again.";
    }
  }
}

export default HoroscopeService;
