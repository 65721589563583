<script setup>
import { useGenerateHoroscope } from "@/composables/useGenerateHoroscope";
import router from "@/router";
import { ref, defineProps, defineEmits } from "vue";
import InterestingLoading from "../InterestingLoading.vue";
import DatePicker from "primevue/datepicker";
import SelectButton from "primevue/selectbutton";
import ToggleButton from "primevue/togglebutton";
import UserService from "@/services/user.service";
import { extractDateOnly, extractTimeOnly } from "@/helpers/index.helper";

const props = defineProps({
  show: {
    type: Boolean,
    required: true,
  },
  fullName: {
    type: String,
    required: true,
  },
});
const emit = defineEmits(["onSubmit"]);

const {
  data,
  isError,
  isSuccess,
  isPending,
  mutateAsync: generateHoroscope,
} = useGenerateHoroscope();

const timeIsUnknown = ref(false);
const dateOfBirth = ref();
const timeOfBirth = ref();

// SIMULATION
const isLoading = ref(false);

async function completeOnboarding() {
  isLoading.value = true; // Set Loading
  const dateOfBirthString = extractDateOnly(dateOfBirth.value);
  const timeOfBirthString = extractTimeOnly(timeOfBirth.value);
  const data = {
    dateOfBirth: dateOfBirthString,
    timeOfBirth: timeOfBirthString,
  };
  const userId = localStorage.getItem("userId");
  await UserService.saveUserInfo(userId, data);

  const horoscopeDTO = {
    userId,
    fullName: props.fullName,
    ...data,
  };
  await generateHoroscope(horoscopeDTO);
  if (isSuccess.value) {
    console.log("success");
    console.log(data.value);
    isLoading.value = false;
    router.push({ name: "personal_dashboard" });
  }
}
</script>
<template>
  <Transition name="fade">
    <div
      class="w-screen h-screen overflow-hidden bg-black bg-opacity-85 absolute top-0 left-0 flex items-center justify-center z-50"
      v-if="show"
    >
      <div
        class="bg-[#F8EDE3] rounded-xl py-5 px-16 flex flex-col items-center justify-center mt-10 w-[40%] font-crimson relative"
      >
        <h1 class="text-black text-3xl font-bold text-center">
          Welcome to Affirmity Members Club.
        </h1>
        <h3 class="mb-5">
          To get your AI-Powered Horoscope for today, enter your date of birth
        </h3>
        <div class="w-full">
          <p class="text-left">Date of birth</p>
          <!-- <input
            v-model="dateOfBirth"
            type="date"
            class="border-[#E2E8F0] bg-[#F1F5F9] border-2 rounded-lg px-3 py-2 w-full mb-4 outline-none"
          /> -->
          <DatePicker
            v-model="dateOfBirth"
            showIcon
            fluid
            iconDisplay="input"
          />
        </div>
        <div class="w-full">
          <p class="text-left">Time of birth</p>
          <!-- <input
            v-model="timeOfBirth"
            :disabled="timeIsUnknown"
            type="time"
            class="border-[#E2E8F0] bg-[#F1F5F9] border-2 rounded-lg px-3 py-2 w-full mb-2 outline-none disabled:opacity-20"
          /> -->
          <DatePicker
            v-model="timeOfBirth"
            showIcon
            fluid
            iconDisplay="input"
            timeOnly
            class="mb-2"
            :disabled="timeIsUnknown"
          >
            <template #inputicon="slotProps">
              <i class="pi pi-clock" @click="slotProps.clickCallback" />
            </template>
          </DatePicker>
          <!-- <p
            class="text-xs p-2 px-3 bg-neutral-700 w-fit rounded-full text-white cursor-pointer mb-5 hover:scale-105 transition-all"
            :class="{
              'opacity-100': timeIsUnknown,
              'opacity-50': !timeIsUnknown,
            }"
            @click="timeIsUnknown = !timeIsUnknown"
          >
            I {{ !timeIsUnknown ? "don't" : "" }} know my time of birth
          </p> -->
          <ToggleButton
            v-model="timeIsUnknown"
            onLabel="I don't know my time birth"
            offLabel="I don't know my time birth"
            class="mb-5 *:text-xs"
          />
        </div>
        <InterestingLoading v-if="isLoading" class="mb-3" />
        <button
          class="bg-[#151515] rounded-lg px-3 py-2 w-full hover:opacity-85 transition-all disabled:opacity-80"
          :disabled="isLoading"
          @click="completeOnboarding"
        >
          <p class="text-[#9CA3AF] text-lg">Get your horoscope</p>
        </button>
      </div>
    </div>
  </Transition>
</template>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
