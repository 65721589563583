<script setup lang="ts">
import { defineProps } from "vue";

const props = defineProps(["feature"]);
</script>

<template>
  <div class="flex gap-2 items-center">
    <img
      alt="Tick Icon Gradient"
      src="@/assets/img/tick-icon.webp"
      class="h-6"
    />
    <h2 class="font-crimson text-[#505050] text-lg font-medium">
      {{ props.feature }}
    </h2>
  </div>
</template>
