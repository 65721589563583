export function extractDateOnly(date: Date) {
  const dateObject = new Date(date);

  const day = String(dateObject.getDate()).padStart(2, "0"); // Ensure two digits for day
  const month = String(dateObject.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed, so add 1
  const year = dateObject.getFullYear();

  // Format as DD-MM-YYYY
  return `${day}-${month}-${year}`;
}

export function extractTimeOnly(date: Date) {
  const dateObject = new Date(date);

  const timeString = dateObject.toTimeString().split(" ")[0];

  return timeString;
}

export function extractFirstName(fullName: string) {
  const firstName = fullName.split(" ")[0];
  return firstName;
}

export function getDashboardDate() {
  const today = new Date();

  const options: Intl.DateTimeFormatOptions = {
    weekday: "long",
    day: "numeric",
    month: "long",
  };

  // Format the date
  const formattedDate = today.toLocaleDateString("en-US", options);

  return formattedDate;
}

export function getGreeting(): string {
  const currentHour = new Date().getHours();

  if (currentHour < 12) {
    return "Good Morning";
  } else if (currentHour >= 12 && currentHour < 18) {
    return "Good Afternoon";
  } else {
    return "Good Evening";
  }
}
