import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, createBlock as _createBlock, Fragment as _Fragment } from "vue"
import _imports_0 from '@/assets/logo-1.webp'
import _imports_1 from '@/assets/img/ai-generated-icon.webp'
import _imports_2 from '@/assets/img/astrology-vector-3.webp'
import _imports_3 from '@/assets/img/astrology-vector-1.webp'
import _imports_4 from '@/assets/img/link-icon.webp'


const _hoisted_1 = { class: "main min-h-screen bg-[#F8EDE3] px-2 py-5 lg:px-10" }
const _hoisted_2 = { class: "body flex flex-col mt-0 px-2 justify-between items-center *:z-10 lg:flex-row lg:px-10" }
const _hoisted_3 = { class: "content flex flex-col w-full lg:w-[46%]" }
const _hoisted_4 = {
  key: 0,
  class: "referral-card bg-white rounded-xl py-5 px-3 flex flex-col mt-0 w-full shadow-md min-h-96 lg:px-10 lg:mt-10"
}
const _hoisted_5 = { class: "mb-6" }
const _hoisted_6 = { class: "font-josefin text-black text-xs uppercase opacity-70" }
const _hoisted_7 = { class: "font-josefin text-black text-2xl font-bold" }
const _hoisted_8 = { class: "h-fit w-full rounded-lg bg-gradient-to-r from-[#C3AAF5] to-[#EE9EC8] p-0.5 horoscope-card" }
const _hoisted_9 = { class: "h-full w-full bg-white rounded-md py-1 px-2 relative overflow-hidden" }
const _hoisted_10 = { class: "flex items-center justify-center gap-1 font-crimson mb-2" }
const _hoisted_11 = {
  key: 0,
  class: "text-[#7F7F7F] m-0 text-xs mt-1"
}
const _hoisted_12 = { class: "text-sm text-black font-josefin text-center" }
const _hoisted_13 = { class: "h-full w-full bg-white border-2 border-[#F8EDE3] rounded-lg py-1 px-2 mt-5" }
const _hoisted_14 = { class: "text-sm text-black font-josefin text-center" }
const _hoisted_15 = { class: "referal-content flex flex-col w-full lg:w-[50%]" }
const _hoisted_16 = {
  key: 0,
  class: "referral-card bg-white rounded-xl py-5 px-3 flex flex-col mt-5 w-full shadow-md h-full lg:px-10 lg:mt-10"
}
const _hoisted_17 = { class: "rounded-full relative h-12 border border-neutral-300 p-4 flex items-center" }
const _hoisted_18 = {
  key: 0,
  class: "text-neutral-600"
}
const _hoisted_19 = {
  key: 1,
  class: "text-neutral-600 text-xs break-all line-clamp-1 lg:text-base"
}
const _hoisted_20 = { class: "social-share-buttons flex gap-3 mt-2 overflow-scroll scrollbar-hide" }
const _hoisted_21 = ["href"]
const _hoisted_22 = ["href"]
const _hoisted_23 = ["href"]
const _hoisted_24 = ["href"]
const _hoisted_25 = ["href"]
const _hoisted_26 = { class: "stats w-full flex gap-3 mt-10" }
const _hoisted_27 = { class: "border border-neutral-200 p-3 flex flex-col justify-center rounded-xl shadow-sm flex-1" }
const _hoisted_28 = { class: "text-4xl text-[#A58E74] font-black mb-2" }
const _hoisted_29 = { class: "border border-neutral-200 p-5 flex flex-col justify-center rounded-xl shadow-sm flex-1" }
const _hoisted_30 = { class: "text-4xl text-[#A58E74] font-black mb-2" }

import FeatureBulletPoint from "@/components/waiting_list/FeatureBulletPoint.vue";
import OnboardingModal from "@/components/waiting_list/OnboardingModal.vue";
import { useWaitinglist } from "@/composables/useWaitinglist";
import Message from "primevue/message";
import Tag from "primevue/tag";
import { onMounted, ref, watch } from "vue";
import { useToast } from "primevue/usetoast";
import Rating from "primevue/rating";
import { usePersonalDashboard } from "@/composables/usePersonalDashboard";
import {
  extractDateOnly,
  extractFirstName,
  getDashboardDate,
  getGreeting,
} from "@/helpers/index.helper";
import router from "@/router";
import Toast from "primevue/toast";
import { useReferralLeaderboard } from "@/composables/useReferralLeaderboard";

const customMessage =
  "FREE AI Horoscope based on your birth chart and many more! Use my referral link to sign up.";
const emailSubject = "Check out Affirmity - AI Astrology!";

// Social Media Share URLs

export default /*@__PURE__*/_defineComponent({
  __name: 'PersonalDashboard',
  setup(__props) {

const fullName = ref("");
const displayContent = ref(false);
const horoscopeRating = ref(0);

// Social Sharing
const referralLink = ref("");

const facebookShareUrl = ref("");
const twitterShareUrl = ref("");

const whatsappShareUrl = ref("");

// Email Share URL
const emailBody = ref("");
const emailShareUrl = ref("");

// Telegram Share URL
const telegramMessage = ref("");
const telegramShareUrl = ref("");

const {
  data,
  isError,
  isSuccess,
  isPending,
  mutate: getUserData,
} = usePersonalDashboard();

const { userRank, nextMilestone, getUserRankAndNextMilestone, errorMessage } =
  useReferralLeaderboard();

const toast = useToast();

onMounted(() => {
  const userId = localStorage.getItem("userId");
  if (!userId) {
    router.push({ name: "sign_up" });
    return;
  }
  getUserData(userId);
  displayContent.value = true;
});

watch(data, (newX) => {
  if (!data.value) return;
  getUserRankAndNextMilestone(data.value);

  referralLink.value = `https://affirmity.com/?referral=${data.value.id}`;
  facebookShareUrl.value = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
    referralLink.value
  )}`;
  twitterShareUrl.value = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
    customMessage
  )}%20${encodeURIComponent(referralLink.value)}`;

  whatsappShareUrl.value = `https://api.whatsapp.com/send?text=${encodeURIComponent(
    customMessage
  )}%20${encodeURIComponent(referralLink.value)}`;

  // Email Share URL
  emailBody.value = `Hi there, I found this awesome app, that generates ${customMessage} ${referralLink.value}`;
  emailShareUrl.value = `mailto:?subject=${encodeURIComponent(
    emailSubject
  )}&body=${encodeURIComponent(emailBody.value)}`;

  // Telegram Share URL
  telegramShareUrl.value = `https://t.me/share/url?url=${encodeURIComponent(
    referralLink.value
  )}&text=${customMessage}`;
});

const copyToClipboard = () => {
  navigator.clipboard
    .writeText(referralLink.value)
    .then(() => {
      toast.add({
        severity: "success",
        summary: "Copied",
        detail: "Referral link copied!",
        life: 1500,
        closable: false,
      });
    })
    .catch((err) => {
      toast.add({
        severity: "error",
        summary: "Error",
        detail: "Failed to copy link",
        life: 1500,
        closable: false,
      });
      console.error("Failed to copy: ", err);
    });
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[21] || (_cache[21] = _createElementVNode("header", { class: "w-full flex items-center justify-between bg-[#151515] px-10 py-3" }, [
      _createElementVNode("a", {
        href: "/",
        class: "no-underline"
      }, [
        _createElementVNode("img", {
          alt: "Affirmity: AI-Powered Astrology & Affirmations Logo",
          src: _imports_0,
          class: "h-8"
        })
      ])
    ], -1)),
    _createElementVNode("div", _hoisted_1, [
      _cache[19] || (_cache[19] = _createElementVNode("div", { class: "w-56 h-56 rounded-full blur-[120px] bg-[#F2BDBA] absolute top-[20%] left-[20%] z-0" }, null, -1)),
      _cache[20] || (_cache[20] = _createElementVNode("div", { class: "w-40 h-40 rounded-full blur-[100px] bg-[#C7C7FF] absolute top-[15%] right-[22%] z-0" }, null, -1)),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("section", _hoisted_3, [
          _createVNode(_Transition, { name: "slide-right" }, {
            default: _withCtx(() => [
              (displayContent.value)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _createElementVNode("div", _hoisted_5, [
                      _createElementVNode("p", _hoisted_6, _toDisplayString(_unref(getDashboardDate)()), 1),
                      _createElementVNode("h3", _hoisted_7, _toDisplayString(_unref(getGreeting)()) + ", " + _toDisplayString(_unref(data)?.fullName ? _unref(extractFirstName)(_unref(data).fullName) : "Loading"), 1)
                    ]),
                    _createElementVNode("div", _hoisted_8, [
                      _createElementVNode("div", _hoisted_9, [
                        _createElementVNode("div", _hoisted_10, [
                          _cache[0] || (_cache[0] = _createElementVNode("img", {
                            src: _imports_1,
                            alt: "AI generated astrology forecast",
                            class: "h-6"
                          }, null, -1)),
                          _cache[1] || (_cache[1] = _createElementVNode("h1", { class: "text-black text-2xl" }, "AI Horoscope", -1)),
                          (_unref(data))
                            ? (_openBlock(), _createElementBlock("p", _hoisted_11, " (" + _toDisplayString(_unref(extractDateOnly)(_unref(data).dateSignedUp.toDate())) + ") ", 1))
                            : _createCommentVNode("", true)
                        ]),
                        _cache[2] || (_cache[2] = _createElementVNode("img", {
                          src: _imports_2,
                          alt: "Get to Horoscope based on your natal chart",
                          class: "h-48 absolute -right-4 top-0 opacity-20"
                        }, null, -1)),
                        _createElementVNode("p", _hoisted_12, _toDisplayString(_unref(data)?.horoscope ?? "Loading..."), 1)
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_13, [
                      _cache[3] || (_cache[3] = _createElementVNode("h1", { class: "text-black text-2xl text-center font-crimson" }, " Your Strengths ", -1)),
                      _cache[4] || (_cache[4] = _createElementVNode("img", {
                        src: _imports_3,
                        alt: "Get to know yourself with astrology",
                        class: "w-20 mx-auto my-2"
                      }, null, -1)),
                      _createElementVNode("p", _hoisted_14, _toDisplayString(_unref(data)?.strengths ?? "Loading..."), 1)
                    ])
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _createElementVNode("section", _hoisted_15, [
          _createVNode(_Transition, { name: "slide-left" }, {
            default: _withCtx(() => [
              (displayContent.value)
                ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                    _cache[13] || (_cache[13] = _createElementVNode("h1", { class: "text-3xl font-crimson mb-2" }, " Invite friends and Win Prizes🎁 ", -1)),
                    _cache[14] || (_cache[14] = _createElementVNode("p", { class: "text-[#7F7F7F] font-josefin text-xs mb-1" }, [
                      _createElementVNode("span", { class: "text-[#A58E74]" }, "1st place: "),
                      _createTextVNode("6 Months Premium Subscription for Free ")
                    ], -1)),
                    _cache[15] || (_cache[15] = _createElementVNode("p", { class: "text-[#7F7F7F] font-josefin text-xs mb-1" }, [
                      _createElementVNode("span", { class: "text-[#A58E74]" }, "2nd-5th places: "),
                      _createTextVNode("50% OFF on first 3 Month ")
                    ], -1)),
                    _cache[16] || (_cache[16] = _createElementVNode("p", { class: "text-[#7F7F7F] font-josefin text-xs mb-1" }, [
                      _createElementVNode("span", { class: "text-[#A58E74]" }, "6th-20th places: "),
                      _createTextVNode("20% OFF on first 2 Month ")
                    ], -1)),
                    _cache[17] || (_cache[17] = _createElementVNode("p", { class: "text-[#7F7F7F] font-josefin text-xs mb-5" }, [
                      _createElementVNode("span", { class: "text-[#A58E74]" }, "Randomly: "),
                      _createTextVNode("Early Access ")
                    ], -1)),
                    _cache[18] || (_cache[18] = _createElementVNode("h3", { class: "text-lg font-crimson mb-2" }, " Share your unique referral link ", -1)),
                    _createElementVNode("div", _hoisted_17, [
                      (_unref(isPending))
                        ? (_openBlock(), _createElementBlock("p", _hoisted_18, "Loading"))
                        : _createCommentVNode("", true),
                      (_unref(data))
                        ? (_openBlock(), _createElementBlock("p", _hoisted_19, _toDisplayString(referralLink.value), 1))
                        : _createCommentVNode("", true),
                      _createElementVNode("div", {
                        class: "absolute right-0 w-12 rounded-tr-full rounded-br-full h-full bg-[#A58E74] py-2 flex items-center justify-center cursor-pointer hover:opacity-90",
                        onClick: copyToClipboard
                      }, _cache[5] || (_cache[5] = [
                        _createElementVNode("img", {
                          src: _imports_4,
                          alt: "",
                          class: "h-[70%]"
                        }, null, -1)
                      ]))
                    ]),
                    _createElementVNode("div", _hoisted_20, [
                      _createElementVNode("a", {
                        href: facebookShareUrl.value,
                        target: "_blank",
                        class: "py-2 px-5 bg-[#A58E74] rounded-full flex items-center justify-center cursor-pointer hover:opacity-90",
                        "aria-label": "Share on Facebook"
                      }, _cache[6] || (_cache[6] = [
                        _createElementVNode("i", {
                          class: "pi pi-facebook text-white text-3xl",
                          style: {"font-size":"1.5rem"}
                        }, null, -1)
                      ]), 8, _hoisted_21),
                      _createElementVNode("a", {
                        href: whatsappShareUrl.value,
                        target: "_blank",
                        class: "py-2 px-5 bg-[#A58E74] rounded-full flex items-center justify-center cursor-pointer hover:opacity-90",
                        "aria-label": "Share on WhatsApp"
                      }, _cache[7] || (_cache[7] = [
                        _createElementVNode("i", {
                          class: "pi pi-whatsapp text-white",
                          style: {"font-size":"1.5rem"}
                        }, null, -1)
                      ]), 8, _hoisted_22),
                      _createElementVNode("a", {
                        href: telegramShareUrl.value,
                        target: "_blank",
                        class: "py-2 px-5 bg-[#A58E74] rounded-full flex items-center justify-center cursor-pointer hover:opacity-90",
                        "aria-label": "Share on Telegram"
                      }, _cache[8] || (_cache[8] = [
                        _createElementVNode("i", {
                          class: "pi pi-telegram text-white",
                          style: {"font-size":"1.5rem"}
                        }, null, -1)
                      ]), 8, _hoisted_23),
                      _createElementVNode("a", {
                        href: twitterShareUrl.value,
                        target: "_blank",
                        class: "py-2 px-5 bg-[#A58E74] rounded-full flex items-center justify-center cursor-pointer hover:opacity-90",
                        "aria-label": "Share on Twitter"
                      }, _cache[9] || (_cache[9] = [
                        _createElementVNode("i", {
                          class: "pi pi-twitter text-white",
                          style: {"font-size":"1.2rem"}
                        }, null, -1)
                      ]), 8, _hoisted_24),
                      _createElementVNode("a", {
                        href: emailShareUrl.value,
                        target: "_blank",
                        class: "py-2 px-5 bg-[#A58E74] rounded-full flex items-center justify-center cursor-pointer hover:opacity-90",
                        "aria-label": "Share via Email"
                      }, _cache[10] || (_cache[10] = [
                        _createElementVNode("i", {
                          class: "pi pi-envelope text-white",
                          style: {"font-size":"1.2rem"}
                        }, null, -1)
                      ]), 8, _hoisted_25)
                    ]),
                    _createElementVNode("div", _hoisted_26, [
                      _createElementVNode("div", _hoisted_27, [
                        _createElementVNode("h3", _hoisted_28, " #" + _toDisplayString(_unref(userRank) ?? "0"), 1),
                        _cache[11] || (_cache[11] = _createElementVNode("p", { class: "text-neutral-400 text-xs" }, "Your current position", -1))
                      ]),
                      _createElementVNode("div", _hoisted_29, [
                        _createElementVNode("h3", _hoisted_30, _toDisplayString(_unref(data)?.referralCount ?? "0"), 1),
                        _cache[12] || (_cache[12] = _createElementVNode("p", { class: "text-neutral-400 text-xs" }, "Referrals", -1))
                      ])
                    ]),
                    (_unref(nextMilestone))
                      ? (_openBlock(), _createBlock(_unref(Message), {
                          key: 0,
                          icon: "pi pi-info-circle",
                          severity: "info",
                          class: "mt-3"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode("Invite " + _toDisplayString(_unref(nextMilestone).neededReferrals) + " friend" + _toDisplayString(_unref(nextMilestone).neededReferrals > 1 ? "s" : "") + " and climb up to the next milestone.", 1)
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _createVNode(_unref(Toast), { position: "top-center" })
  ], 64))
}
}

})