<script setup lang="ts"></script>

<template>
  <div class="bg-[#151515] px-10 py-10 flex flex-col items-center mt-10">
    <h2 class="font-crimson text-white text-2xl text-center">
      What is Affirmity?
    </h2>
    <img
      src="@/assets/img/natal-chart-illustration.webp"
      alt="Natal Chart"
      class="h-48"
    />
    <p
      class="font-josefin text-white text-base font-light text-center max-w-md mb-10"
    >
      At its core, Soul AI leverages the power of AI to deliver personalised
      horoscopes, daily affirmations, and moon phase insights tailored to the
      user's astrological profile. It's a daily tool that turns desires into
      realities by making self-improvement feel effortless and instantly
      accessible.
    </p>
    <img
      src="@/assets/img/astrology-hand-illustration.webp"
      alt="Natal Chart"
      class="h-48"
    />
    <h2 class="font-crimson text-white text-2xl text-center mb-1 mt-2">
      Our Mission is Clear
    </h2>
    <p
      class="font-josefin text-white text-base font-light text-center max-w-md"
    >
      To harmonise technology with the human experience, providing emotional
      clarity, well-being, and guidance to those seeking it
    </p>
  </div>
</template>

<style lang="scss" scoped></style>
