// firebaseConfig.ts
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyC2zSlyzXroEsCt8VzCNw3eg3bKfBQyitM",
  authDomain: "affirmity-5f39a.firebaseapp.com",
  projectId: "affirmity-5f39a",
  storageBucket: "affirmity-5f39a.appspot.com",
  messagingSenderId: "605122223321",
  appId: "1:605122223321:web:d0d9a1bb99608b6fc2b2c8",
};
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };
