<script setup lang="ts"></script>

<template>
  <div class="main min-h-screen px-10 py-5">
    <img
      src="@/assets/img/space-bg.webp"
      alt="Space Astrology"
      class="absolute w-screen h-screen top-0 left-0 -z-10 object-center object-cover"
    />
    <div
      class="w-56 h-56 rounded-full blur-[190px] bg-[#F2BDBA] absolute bottom-[5%] left-[0%] z-0"
    />
    <div
      class="w-40 h-40 rounded-full blur-[100px] bg-[#C7C7FF] absolute top-[15%] right-[22%] z-0"
    />
    <img
      src="@/assets/img/meditation-icon.png"
      alt="Meditating Human Icon"
      class="w-40 absolute top-[15%] left-[0%] z-0 opacity-15"
    />
    <header class="w-full flex items-center justify-between">
      <a href="/" class="no-underline">
        <img
          alt="Affirmity: AI-Powered Astrology & Affirmations Logo"
          src="@/assets/logo.webp"
          class="h-12"
        />
      </a>
      <div class="flex gap-4">
        <router-link to="/" class="font-crimson text-white"
          >Waitinglist</router-link
        >
        <router-link to="/founder-story" class="font-crimson text-[#C7C7FF]"
          >Story</router-link
        >
      </div>
    </header>
    <div class="content flex flex-col px-16">
      <h1 class="font-crimson text-white text-4xl font-bold mt-10 mb-5">
        Founder Story
      </h1>
      <p class="text-[#cfcfcf] font-josefin text-base">
        As the founder of Affirmity, I wasn’t always the calm, focused, and
        aligned person I am today. In fact, the inspiration for creating this
        app came during one of the most challenging times in my life.
        <br /><br />Like many of you, I used to feel overwhelmed by the
        uncertainties of life—whether it was decisions about my career,
        relationships, or just navigating the daily stress of modern living. I
        found myself constantly searching for meaning and guidance, something to
        help me understand the bigger picture and how I fit into it. That’s when
        I turned to astrology. <br /><br />At first, I thought astrology was
        just about reading daily horoscopes—general predictions that never quite
        spoke to me. But as I dug deeper, I realized the true power of astrology
        is its ability to connect our personal journeys to the movements of the
        cosmos. It wasn’t about what the stars told me, it was about how I
        connected with them and how they could provide clarity in my
        decision-making. <br /><br />However, I was missing something:
        personalization. The daily horoscopes I found were too vague. They
        didn’t reflect who I was or the unique challenges I was facing. That’s
        when it clicked—I could combine the ancient wisdom of astrology with
        modern AI to deliver truly personalized guidance. Something that could
        speak to you, based on where you are, who you are, and what you're
        feeling. <br /><br />This is how Affirmity was born. I wanted to create
        an app that wasn’t just another horoscope platform, but a tool that
        would help people gain the clarity, focus, and empowerment they need to
        navigate their lives. By integrating AI-powered insights with
        affirmations and astrological wisdom, Affirmity is designed to help you
        wake up each day feeling aligned with your true path, armed with
        practical guidance and inspiring affirmations that resonate with you
        personally. <br /><br />For me, Affirmity is more than just an app—it’s
        the answer to a lifelong question: How can we find clarity in the chaos?
        I want Affirmity to be that guide for you—the tool that helps you
        connect to your cosmic path, empowering you to make decisions that align
        with who you are and where you’re going. <br /><br />Every time you
        check your daily horoscope, feel the resonance of an affirmation, or ask
        a question in our astrology chat, know that you’re part of a community
        of people who are also seeking their truth, guided by the stars.
      </p>
    </div>
  </div>
</template>
