import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/img/tick-icon.webp'


const _hoisted_1 = { class: "flex gap-2 items-center" }
const _hoisted_2 = { class: "font-crimson text-[#505050] text-lg font-medium" }




export default /*@__PURE__*/_defineComponent({
  __name: 'FeatureBulletPoint',
  props: ["feature"],
  setup(__props) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("img", {
      alt: "Tick Icon Gradient",
      src: _imports_0,
      class: "h-6"
    }, null, -1)),
    _createElementVNode("h2", _hoisted_2, _toDisplayString(props.feature), 1)
  ]))
}
}

})