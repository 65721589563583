// src/composables/useFirebase.js
import { ref } from "vue";
import {
  addDoc,
  collection,
  query,
  where,
  getDocs,
  doc,
  runTransaction,
} from "firebase/firestore";
import { db } from "@/firebaseConfig";

export function useWaitinglist() {
  const errorMessage = ref("");

  // Function to check if the user already signed up from the same device (localStorage)
  const hasSignedUpBefore = () => {
    return localStorage.getItem("waitListSignedUp") === "true";
  };

  // Function to save user to Firestore
  const saveUser = async (
    fullName: string,
    email: string,
    referralId: string | null
  ) => {
    try {
      // Check if email already exists in Firestore
      const q = query(collection(db, "waitlist"), where("email", "==", email));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        errorMessage.value = "This email is already registered.";
        return;
      }
      // Add new user data to Firestore
      const res = await addDoc(collection(db, "waitlist"), {
        fullName,
        email,
        dateSignedUp: new Date(),
        referralBy: referralId || null,
      });

      localStorage.setItem("userId", res.id);
      // Set flag in localStorage to prevent multiple signups
      localStorage.setItem("waitListSignedUp", "true");

      if (referralId) {
        await incrementReferralCount(referralId);
      }
    } catch (error) {
      errorMessage.value = "Couldn't join at the moment. Please try again.";
      console.error("Error saving user:", error);
    }
  };

  const incrementReferralCount = async (referrerId: string) => {
    const referrerRef = doc(db, "waitlist", referrerId);
    await runTransaction(db, async (transaction) => {
      const referrerDoc = await transaction.get(referrerRef);
      if (referrerDoc.exists()) {
        const newReferralCount = (referrerDoc.data().referralCount || 0) + 1;
        transaction.update(referrerRef, { referralCount: newReferralCount });
      }
    });
  };

  return {
    errorMessage,
    saveUser,
    hasSignedUpBefore,
  };
}
