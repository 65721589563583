import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { updatePreset } from "@primevue/themes";


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

updatePreset({
  semantic: {
    primary: {
      50: "#5353CA",
      100: "#5353CA",
      200: "#5353CA",
      300: "#5353CA",
      400: "#5353CA",
      500: "#5353CA",
      600: "#5353CA",
      700: "#5353CA",
      800: "#5353CA",
      900: "#5353CA",
      950: "#5353CA",
    },
  },

  components: {
    toast: {
      content: {
        padding: "0.5rem",
      },
      summary: {
        "font.size": "0.88rem",
        "font.weight": "400",
      },
      detail: {
        "font.size": "0.8rem",
        "font.weight": "300",
      },
      "text.gap": "0.1rem",
      blur: "4px",
      success: {
        background: "color-mix(in srgb, #f0fdf4,transparent 20%)",
      },
      "icon.size": "1.2rem",
      width: "23rem",
    },
    message: {
      "text.font.size": "0.9rem",
    },
  },
});

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createBlock(_component_router_view))
}
}

})