<script setup lang="ts">
import FeatureBulletPoint from "@/components/waiting_list/FeatureBulletPoint.vue";
import OnboardingModal from "@/components/waiting_list/OnboardingModal.vue";
import WhatIsItSection from "@/components/WhatIsItSection.vue";
import { useWaitinglist } from "@/composables/useWaitinglist";
import Toast from "primevue/toast";
import { useToast } from "primevue/usetoast";
import { onMounted, ref } from "vue";

const displayContent = ref(false);

const fullName = ref("");
const email = ref("");

const isOnboarding = ref(false);

// localStorage.clear();

// Destructure the composable for Firebase operations
const { saveUser, errorMessage, hasSignedUpBefore } = useWaitinglist();

const toast = useToast();

onMounted(() => {
  displayContent.value = true;

  const urlParams = new URLSearchParams(window.location.search);
  const referralId = urlParams.get("referral");
  if (referralId) {
    localStorage.setItem("referralId", referralId);
  }
});

const onJoinWaitlistClick = async () => {
  if (!fullName.value || !email.value) {
    errorMessage.value = "Please enter your details";
    return;
  }
  if (hasSignedUpBefore()) {
    errorMessage.value = "Seems like you already signed up";
    return;
  }
  const referralId = localStorage.getItem("referralId");

  await saveUser(fullName.value, email.value, referralId);

  if (!errorMessage.value) {
    toast.add({
      severity: "success",
      summary: "Joined Successfully",
      detail: "You have been added to waitlist",
      life: 1500,
    });
    isOnboarding.value = true;
  }
};
</script>

<template>
  <header
    class="w-full flex items-center justify-between bg-[#151515] px-10 py-3"
  >
    <a href="/" class="no-underline">
      <img
        alt="Affirmity: AI-Powered Astrology & Affirmations Logo"
        src="@/assets/logo-1.webp"
        class="h-8"
      />
    </a>
    <!-- <div class="flex gap-4">
      <router-link to="/" class="font-crimson text-[#C7C7FF]"
        >Waitinglist</router-link
      >
      <router-link
        to="/founder-story"
        class="text-white font-crimson hover:text-[#C7C7FF]"
        >Story</router-link
      >
    </div> -->
  </header>
  <div class="main px-10 py-5">
    <div
      class="w-56 h-56 rounded-full blur-[150px] bg-[#F2BDBA] absolute bottom-[20%] left-[30%] z-0"
    />
    <div
      class="w-40 h-40 rounded-full blur-[100px] bg-[#C7C7FF] absolute top-[15%] right-[22%] z-0"
    />
    <!-- <div
      id="sticky-banner"
      tabindex="-1"
      class="fixed top-0 start-0 z-50 flex justify-between w-full p-1 bg-[#d8cec6] bg-opacity-80"
    >
      <div class="flex items-center mx-auto">
        <p
          class="flex items-center text-sm font-normal text-black font-crimson"
        >
          Get Free AI Horoscope and Characterization Based on Your Natal Chart
        </p>
      </div>
    </div> -->

    <div class="body flex mt-10 px-10 justify-between items-center *:z-10">
      <Transition name="slide-right">
        <div class="content flex flex-col w-[49%]" v-if="displayContent">
          <h1 class="font-crimson text-[#151515] text-4xl font-bold">
            AI-Powered Astrology & Affirmations
          </h1>
          <h4 class="font-crimson text-[#505050] text-xl mb-6">
            Join waitlist to get early access and free gifts
          </h4>
          <FeatureBulletPoint
            feature="Daily Personalised Horoscope"
            class="mb-2"
          />
          <FeatureBulletPoint feature="Daily Affirmations" class="mb-2" />
          <FeatureBulletPoint feature="AI Chat: Ask the Stars Anything" />

          <div
            class="sign-up-card bg-white rounded-xl py-5 px-16 flex flex-col items-center mt-10 w-[80%] font-crimson"
          >
            <h1 class="text-black text-3xl font-bold text-center mb-8">
              Get Free AI-Powered Horoscope for Today
            </h1>
            <h3></h3>
            <input
              v-model="fullName"
              placeholder="Full Name"
              class="border-[#E2E8F0] bg-[#F1F5F9] border-2 rounded-lg px-3 py-2 w-full mb-4 outline-none"
            />
            <input
              v-model="email"
              placeholder="Email"
              class="border-[#E2E8F0] bg-[#F1F5F9] border-2 rounded-lg px-3 py-2 w-full mb-4 outline-none"
            />
            <p v-if="errorMessage" class="mb-4 text-red-800">
              {{ errorMessage }}
            </p>
            <button
              class="bg-[#151515] rounded-lg px-3 py-2 w-full hover:opacity-85"
              @click="onJoinWaitlistClick"
            >
              <p class="text-[#9CA3AF] text-lg">Join waitlist</p>
            </button>
            <h3 class="text-[#9CA3AF] text-lg mt-5 text-center">
              Get chance to win
              <span class="font-black text-[#3e4146]">6 free months</span>
              premium subscription.
            </h3>
          </div>
        </div>
      </Transition>
      <Transition name="slide-left">
        <img
          v-if="displayContent"
          src="@/assets/img/hero-showcase.webp"
          alt="Daily Horoscope & Astrology Mobile App"
          class="w-[30%]"
        />
      </Transition>
    </div>
  </div>
  <WhatIsItSection />
  <OnboardingModal :show="isOnboarding" :fullName="fullName" />
  <Toast position="top-center" />
</template>

<style scoped lang="scss">
:global(body) {
  background-color: #f8ede3;
}
</style>
