<script setup lang="ts"></script>
<template>
  <div class="bg-[#bdb5ae] p-3 rounded-lg">
    <div class="w-full bg-gray-700 rounded-full h-2">
      <div
        class="loading-bar bg-gradient-to-r from-[#5353CA] via-[#FFE6E6] to-[#5353CA] h-2 rounded-full"
      ></div>
    </div>
    <h5
      class="text-[#151515] text-base font-normal font-crimson text-left mt-2"
    >
      Your moon sign reveals your emotional self—it's like your heart's compass!
    </h5>
  </div>
</template>

<style scoped>
/* Loading bar with animated gradient */
.loading-bar {
  width: 100%; /* Adjust the width as needed */
  background-size: 200% 100%;
  animation: flowing-gradient 3s linear infinite;
}

/* Keyframes to animate the gradient */
@keyframes flowing-gradient {
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: 200% 0%;
  }
}
</style>
