<script setup lang="ts">
import FeatureBulletPoint from "@/components/waiting_list/FeatureBulletPoint.vue";
import OnboardingModal from "@/components/waiting_list/OnboardingModal.vue";
import { useWaitinglist } from "@/composables/useWaitinglist";
import Message from "primevue/message";
import Tag from "primevue/tag";
import { onMounted, ref, watch } from "vue";
import { useToast } from "primevue/usetoast";
import Rating from "primevue/rating";
import { usePersonalDashboard } from "@/composables/usePersonalDashboard";
import {
  extractDateOnly,
  extractFirstName,
  getDashboardDate,
  getGreeting,
} from "@/helpers/index.helper";
import router from "@/router";
import Toast from "primevue/toast";
import { useReferralLeaderboard } from "@/composables/useReferralLeaderboard";

const fullName = ref("");
const displayContent = ref(false);
const horoscopeRating = ref(0);

// Social Sharing
const referralLink = ref("");

const customMessage =
  "FREE AI Horoscope based on your birth chart and many more! Use my referral link to sign up.";
const emailSubject = "Check out Affirmity - AI Astrology!";

// Social Media Share URLs
const facebookShareUrl = ref("");
const twitterShareUrl = ref("");

const whatsappShareUrl = ref("");

// Email Share URL
const emailBody = ref("");
const emailShareUrl = ref("");

// Telegram Share URL
const telegramMessage = ref("");
const telegramShareUrl = ref("");

const {
  data,
  isError,
  isSuccess,
  isPending,
  mutate: getUserData,
} = usePersonalDashboard();

const { userRank, nextMilestone, getUserRankAndNextMilestone, errorMessage } =
  useReferralLeaderboard();

const toast = useToast();

onMounted(() => {
  const userId = localStorage.getItem("userId");
  if (!userId) {
    router.push({ name: "sign_up" });
    return;
  }
  getUserData(userId);
  displayContent.value = true;
});

watch(data, (newX) => {
  if (!data.value) return;
  getUserRankAndNextMilestone(data.value);

  referralLink.value = `https://affirmity.com/?referral=${data.value.id}`;
  facebookShareUrl.value = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
    referralLink.value
  )}`;
  twitterShareUrl.value = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
    customMessage
  )}%20${encodeURIComponent(referralLink.value)}`;

  whatsappShareUrl.value = `https://api.whatsapp.com/send?text=${encodeURIComponent(
    customMessage
  )}%20${encodeURIComponent(referralLink.value)}`;

  // Email Share URL
  emailBody.value = `Hi there, I found this awesome app, that generates ${customMessage} ${referralLink.value}`;
  emailShareUrl.value = `mailto:?subject=${encodeURIComponent(
    emailSubject
  )}&body=${encodeURIComponent(emailBody.value)}`;

  // Telegram Share URL
  telegramShareUrl.value = `https://t.me/share/url?url=${encodeURIComponent(
    referralLink.value
  )}&text=${customMessage}`;
});

const copyToClipboard = () => {
  navigator.clipboard
    .writeText(referralLink.value)
    .then(() => {
      toast.add({
        severity: "success",
        summary: "Copied",
        detail: "Referral link copied!",
        life: 1500,
        closable: false,
      });
    })
    .catch((err) => {
      toast.add({
        severity: "error",
        summary: "Error",
        detail: "Failed to copy link",
        life: 1500,
        closable: false,
      });
      console.error("Failed to copy: ", err);
    });
};
</script>

<template>
  <header
    class="w-full flex items-center justify-between bg-[#151515] px-10 py-3"
  >
    <a href="/" class="no-underline">
      <img
        alt="Affirmity: AI-Powered Astrology & Affirmations Logo"
        src="@/assets/logo-1.webp"
        class="h-8"
      />
    </a>
    <!-- <div class="flex gap-4">
      <router-link to="/" class="font-crimson text-[#C7C7FF]"
        >Waitinglist</router-link
      >
      <router-link
        to="/founder-story"
        class="text-white font-crimson hover:text-[#C7C7FF]"
        >Story</router-link
      >
    </div> -->
  </header>
  <div class="main min-h-screen bg-[#F8EDE3] px-2 py-5 lg:px-10">
    <div
      class="w-56 h-56 rounded-full blur-[120px] bg-[#F2BDBA] absolute top-[20%] left-[20%] z-0"
    />
    <div
      class="w-40 h-40 rounded-full blur-[100px] bg-[#C7C7FF] absolute top-[15%] right-[22%] z-0"
    />
    <div
      class="body flex flex-col mt-0 px-2 justify-between items-center *:z-10 lg:flex-row lg:px-10"
    >
      <section class="content flex flex-col w-full lg:w-[46%]">
        <Transition name="slide-right">
          <!-- #d8cec6 -->
          <div
            class="referral-card bg-white rounded-xl py-5 px-3 flex flex-col mt-0 w-full shadow-md min-h-96 lg:px-10 lg:mt-10"
            v-if="displayContent"
          >
            <div class="mb-6">
              <p class="font-josefin text-black text-xs uppercase opacity-70">
                {{ getDashboardDate() }}
              </p>
              <h3 class="font-josefin text-black text-2xl font-bold">
                {{ getGreeting() }},
                {{
                  data?.fullName ? extractFirstName(data.fullName) : "Loading"
                }}
              </h3>
            </div>
            <!-- from-[#F8EDE3] via-[#A58E74] to-[#F8EDE3] -->
            <div
              class="h-fit w-full rounded-lg bg-gradient-to-r from-[#C3AAF5] to-[#EE9EC8] p-0.5 horoscope-card"
            >
              <div
                class="h-full w-full bg-white rounded-md py-1 px-2 relative overflow-hidden"
              >
                <div
                  class="flex items-center justify-center gap-1 font-crimson mb-2"
                >
                  <img
                    src="@/assets/img/ai-generated-icon.webp"
                    alt="AI generated astrology forecast"
                    class="h-6"
                  />
                  <h1 class="text-black text-2xl">AI Horoscope</h1>
                  <p class="text-[#7F7F7F] m-0 text-xs mt-1" v-if="data">
                    ({{ extractDateOnly(data.dateSignedUp.toDate()) }})
                  </p>
                </div>
                <img
                  src="@/assets/img/astrology-vector-3.webp"
                  alt="Get to Horoscope based on your natal chart"
                  class="h-48 absolute -right-4 top-0 opacity-20"
                />
                <p class="text-sm text-black font-josefin text-center">
                  {{ data?.horoscope ?? "Loading..." }}
                </p>
              </div>
            </div>
            <!-- <p class="text-sm font-crimson text-[#7F7F7F] text-center mt-5">
              How did today’s horoscope resonate with you?
            </p>
            <Rating v-model="horoscopeRating" class="mt-2 self-center" /> -->

            <div
              class="h-full w-full bg-white border-2 border-[#F8EDE3] rounded-lg py-1 px-2 mt-5"
            >
              <h1 class="text-black text-2xl text-center font-crimson">
                Your Strengths
              </h1>
              <img
                src="@/assets/img/astrology-vector-1.webp"
                alt="Get to know yourself with astrology"
                class="w-20 mx-auto my-2"
              />
              <p class="text-sm text-black font-josefin text-center">
                {{ data?.strengths ?? "Loading..." }}
              </p>
            </div>
          </div>
        </Transition>
      </section>
      <section class="referal-content flex flex-col w-full lg:w-[50%]">
        <Transition name="slide-left">
          <div
            class="referral-card bg-white rounded-xl py-5 px-3 flex flex-col mt-5 w-full shadow-md h-full lg:px-10 lg:mt-10"
            v-if="displayContent"
          >
            <h1 class="text-3xl font-crimson mb-2">
              Invite friends and Win Prizes🎁
            </h1>
            <p class="text-[#7F7F7F] font-josefin text-xs mb-1">
              <span class="text-[#A58E74]">1st place: </span>6 Months Premium
              Subscription for Free
            </p>
            <p class="text-[#7F7F7F] font-josefin text-xs mb-1">
              <span class="text-[#A58E74]">2nd-5th places: </span>50% OFF on
              first 3 Month
            </p>
            <p class="text-[#7F7F7F] font-josefin text-xs mb-1">
              <span class="text-[#A58E74]">6th-20th places: </span>20% OFF on
              first 2 Month
            </p>
            <p class="text-[#7F7F7F] font-josefin text-xs mb-5">
              <span class="text-[#A58E74]">Randomly: </span>Early Access
            </p>
            <h3 class="text-lg font-crimson mb-2">
              Share your unique referral link
            </h3>
            <div
              class="rounded-full relative h-12 border border-neutral-300 p-4 flex items-center"
            >
              <p class="text-neutral-600" v-if="isPending">Loading</p>
              <p
                class="text-neutral-600 text-xs break-all line-clamp-1 lg:text-base"
                v-if="data"
              >
                {{ referralLink }}
              </p>

              <div
                class="absolute right-0 w-12 rounded-tr-full rounded-br-full h-full bg-[#A58E74] py-2 flex items-center justify-center cursor-pointer hover:opacity-90"
                @click="copyToClipboard"
              >
                <img src="@/assets/img/link-icon.webp" alt="" class="h-[70%]" />
              </div>
            </div>
            <div
              class="social-share-buttons flex gap-3 mt-2 overflow-scroll scrollbar-hide"
            >
              <!-- Facebook -->
              <a
                :href="facebookShareUrl"
                target="_blank"
                class="py-2 px-5 bg-[#A58E74] rounded-full flex items-center justify-center cursor-pointer hover:opacity-90"
                aria-label="Share on Facebook"
              >
                <i
                  class="pi pi-facebook text-white text-3xl"
                  style="font-size: 1.5rem"
                ></i>
              </a>

              <!-- WhatsApp -->
              <a
                :href="whatsappShareUrl"
                target="_blank"
                class="py-2 px-5 bg-[#A58E74] rounded-full flex items-center justify-center cursor-pointer hover:opacity-90"
                aria-label="Share on WhatsApp"
              >
                <i
                  class="pi pi-whatsapp text-white"
                  style="font-size: 1.5rem"
                ></i>
              </a>

              <!-- Telegram -->
              <a
                :href="telegramShareUrl"
                target="_blank"
                class="py-2 px-5 bg-[#A58E74] rounded-full flex items-center justify-center cursor-pointer hover:opacity-90"
                aria-label="Share on Telegram"
              >
                <i
                  class="pi pi-telegram text-white"
                  style="font-size: 1.5rem"
                ></i>
              </a>

              <!-- Twitter -->
              <a
                :href="twitterShareUrl"
                target="_blank"
                class="py-2 px-5 bg-[#A58E74] rounded-full flex items-center justify-center cursor-pointer hover:opacity-90"
                aria-label="Share on Twitter"
              >
                <i
                  class="pi pi-twitter text-white"
                  style="font-size: 1.2rem"
                ></i>
              </a>

              <!-- Email -->
              <a
                :href="emailShareUrl"
                target="_blank"
                class="py-2 px-5 bg-[#A58E74] rounded-full flex items-center justify-center cursor-pointer hover:opacity-90"
                aria-label="Share via Email"
              >
                <i
                  class="pi pi-envelope text-white"
                  style="font-size: 1.2rem"
                ></i>
              </a>
            </div>
            <div class="stats w-full flex gap-3 mt-10">
              <div
                class="border border-neutral-200 p-3 flex flex-col justify-center rounded-xl shadow-sm flex-1"
              >
                <h3 class="text-4xl text-[#A58E74] font-black mb-2">
                  #{{ userRank ?? "0" }}
                </h3>
                <p class="text-neutral-400 text-xs">Your current position</p>
              </div>
              <div
                class="border border-neutral-200 p-5 flex flex-col justify-center rounded-xl shadow-sm flex-1"
              >
                <h3 class="text-4xl text-[#A58E74] font-black mb-2">
                  {{ data?.referralCount ?? "0" }}
                </h3>
                <p class="text-neutral-400 text-xs">Referrals</p>
              </div>
            </div>
            <!-- <Tag
            icon="pi pi-info-circle"
            severity="info"
            value="Info"
            class="mt-2"
          ></Tag> -->
            <Message
              icon="pi pi-info-circle"
              severity="info"
              class="mt-3"
              v-if="nextMilestone"
              >Invite {{ nextMilestone.neededReferrals }} friend{{
                nextMilestone.neededReferrals > 1 ? "s" : ""
              }}
              and climb up to the next milestone.</Message
            >
          </div>
        </Transition>
      </section>
    </div>
  </div>
  <Toast position="top-center" />
</template>

<style scoped>
@keyframes flowing-gradient {
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: 200% 0%;
  }
}

.horoscope-card {
  background-size: 200%;
  animation: flowing-gradient 3s linear infinite;
}
</style>
